<template>
  <div class="container">
    <a-select placeholder="请选择" style="width: 120px" v-model="selectedCity">
      <a-select-option
        v-for="item in [{ name: '全省' }, ...cityList]"
        :key="item.name"
        :value="item.name"
        >{{ item.name }}</a-select-option
      >
    </a-select>

    <div class="item" v-for="(item, index) in filteredList" :key="index">
      <img
        :src="`https://adm.ac.cn/static/img/warning/${item.alarmType}_${item.alarmLevel}.png`"
        alt=""
      />
      <div>
        <div class="title">
          {{ item.issueContent }}
        </div>

        <div class="left footer">
          <div class="">
            <span style="color: #e44e35"> 预警区域： </span>
            {{ item.province }}{{ item.city }}{{ item.areaName }}
          </div>

          <div class="">
            <span style="color: #e44e35"> 预警等级： </span>
            {{ item.alarmType }}{{ item.alarmLevel }}
          </div>

          <div class="">
            <span style="color: #e44e35"> 发布时间： </span>
            {{ item.issuetime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      selectedCity: "全省",
    };
  },
  computed: {
    ...mapState("alert", ["list"]),
    ...mapState("city", {
      cityList: "list",
    }),

    filteredList() {
      if (this.selectedCity !== "全省") {
        return this.list.filter(
          (item) => item.city && item.city.indexOf(this.selectedCity) > -1
        );
      } else {
        return this.list;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  img {
    width: 110px;
    margin-right: 12px;
  }
  .title {
    margin-bottom: 8px;
  }

  & > div {
    padding: 8px 0;
  }

  .footer div {
    margin-right: 12px;
  }
}
</style>